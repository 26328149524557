import { template as template_1893920079954934bb5eb6c0a4a90b74 } from "@ember/template-compiler";
const WelcomeHeader = template_1893920079954934bb5eb6c0a4a90b74(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
