import { template as template_68ce29b8bc1c48df8c70a955ca0f5aea } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_68ce29b8bc1c48df8c70a955ca0f5aea(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
