import { template as template_833925733f3a49f4a37b0e1b88d33124 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_833925733f3a49f4a37b0e1b88d33124(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
