import { template as template_acc5fecbf18b4e2e8693296f530c9c45 } from "@ember/template-compiler";
const EmptyState = template_acc5fecbf18b4e2e8693296f530c9c45(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
