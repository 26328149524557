import { template as template_332c27cbbff742b29487fe81fcf6d6c7 } from "@ember/template-compiler";
const FKLabel = template_332c27cbbff742b29487fe81fcf6d6c7(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
