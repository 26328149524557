import { template as template_de9073fd71a34d3a98ed7f126cd8c4a2 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_de9073fd71a34d3a98ed7f126cd8c4a2(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
