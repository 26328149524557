import { template as template_34b30bedf6e54fd7a32cf303459e2a1b } from "@ember/template-compiler";
import Component from "@glimmer/component";
export default class FKControlCustom extends Component {
    static controlType = "custom";
    static{
        template_34b30bedf6e54fd7a32cf303459e2a1b(`
    <div class="form-kit__control-custom">
      {{yield}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
